//info text for each chart
const infoText = {
  studentMetricsBarChart:
    "This plot shows the distribution of selected interactions for the class. Switch the axis values using the filters to the left. Click on a bar to populate a table with the chosen data.",
  aggregatePageViewsChart1:
    "This graph shows the number of page views ",
  aggregatePageViewsChart2:
    ". Switch the unit of time using the filters to the left.",
  studentTextbookEngagement:
    "for a selected student compared to the total class textbook activity for all pages in ",
  pageActivity:
    "for a selected page compared to the total views on all pages in ",
  individualPageViewsChart:
    "This graph shows data for an individual page. Switch the unit of time using the filters to the left.",
  individualAssignmentsChart:
    "This plot shows interaction and performance metrics for a single assignment over time. Switch the unit of time using the filters to the left.",
  assignmentGradesChart:
    "This graph shows grade distribution of a selected assignment.",
  courseText:
    "Please choose a course.",
  loadingMessage:
    "Loading, please wait",
  dataFilter:
    "Use the options below to filter the data by date and metatag. Upload a roster to connect student enrollment data. Click apply to load the filtered data.",
  courseStructureDropdown:
    "Choose a unit from the course to focus on. This filter only affects the LibreText data.",
  noDataMessage:
    "No data to display.",
  toggleText:
    "Toggle the following options.  The changes will take effect immediately without hitting apply.",
  textbookChapterChart:
    "This graph shows textbook chapter activity for an entire class. Choose a student to see individual activity.",
  studentTextbookEngagementChart:
    "  Click on one of the red bars to see what pages the student viewed on that day.",
  allAdaptAssignmentsChart:
    "This graph shows the scores on each assignment for a selected student compared to the average class performance."
}

export default infoText;
